import gql from "graphql-tag";

/* REPORT */
export const Q_LIST_REPORT = gql`
  query listLaporan(
    $page: Int
    $limit: Int
    $keyword: String
    $from: [String!]
    $to: String
    $country_id: [Int!]
    $region_id: [Int!]
    $prov_id: [String!]
    $kab_id: [String!]
    $r1: [Int!]
    $r2: [Int!]
    $dis: [Int!]
    $petani: [String!]
    $produk: [String!]
    $start: DateTime
    $end: DateTime
    $dateType: filterDateType
  ) {
    listLaporan(
      listLaporanInput: {
        page: $page
        limit: $limit
        order: { sortBy: DESC, orderBy: CREATED_AT }
        search: {
          keyword: $keyword
          from: $from
          to: $to
          country_id: $country_id
          region_id: $region_id
          prov_id: $prov_id
          kab_id: $kab_id
          kec_id: []
          kel_id: []
          r1: $r1
          r2: $r2
          dis: $dis
          petani_id: $petani
          produk_id: $produk
          start_date: $start
          end_date: $end
          dateType: $dateType
        }
      }
    ) {
      page
      total
      totalPage
      laporans {
        id
        froms {
          id
          name
        }
        countrys {
          id
          country
        }
        regions {
          id
          region
        }
        provs {
          id
          province
        }
        kabs {
          id
          kabupaten
        }
        day_report
        month
        year
        produknames {
          id
          brand
        }
        price
        quantity
        value
        retailers1 {
          id
          name
          owner
        }
        retailers2 {
          id
          name
          owner
        }
        distributors {
          id
          name
          owner
        }
        invoice
      }
    }
  }
`;
export const M_DEL_REPORT = gql`
  mutation delLaporan($id: ID!) {
    deleteLaporan(id: $id) {
      id
    }
  }
`;
export const M_ADD_REPORT = gql`
  mutation addLaporan(
    $from: String
    $to: String
    $bulan: String
    $tahun: String
    $country: ID
    $region: ID
    $prov_id: String
    $day_report: DateTime
    $produk_id: String
    $price: Int
    $target_quantity: Int
    $target_value: String
    $r1: ID
    $r2: ID
    $distributor: ID
    $invoice: String
  ) {
    createLaporan(
      createLaporanInput: {
        from: $from
        to: $to
        month: $bulan
        year: $tahun
        country_id: $country
        region_id: $region
        prov_id: $prov_id
        day_report: $day_report
        produk_id: $produk_id
        price: $price
        quantity: $target_quantity
        value: $target_value
        r1: $r1
        r2: $r2
        distributor: $distributor
        invoice: $invoice
      }
    ) {
      id
    }
  }
`;
export const M_EDIT_REPORT = gql`
  mutation updateLaporan(
    $id: ID!
    $to: String
    $bulan: String
    $tahun: String
    $from: String
    $country: ID
    $region: ID
    $prov_id: String
    $day_report: DateTime
    $produk_id: String
    $price: Int
    $target_quantity: Int
    $target_value: String
    $r1: ID
    $r2: ID
    $distributor: ID
    $invoice: String
  ) {
    updateLaporan(
      updateLaporanInput: {
        id: $id
        from: $from
        to: $to
        month: $bulan
        year: $tahun
        country_id: $country
        region_id: $region
        prov_id: $prov_id
        day_report: $day_report
        produk_id: $produk_id
        price: $price
        quantity: $target_quantity
        value: $target_value
        r1: $r1
        r2: $r2
        distributor: $distributor
        invoice: $invoice
      }
    ) {
      id
    }
  }
`;

/* SET TARGET */
export const Q_LIST_TARGET = gql`
  query listTarget(
    $page: Int
    $limit: Int
    $keyword: String
    $from: String
    $to: String
    $role: roleType
    $tahun: String
    $bulan: String
    $country_id: [ID!]
    $region_id: [ID!]
    $prov: [String!]
    $kab: [String!]
    $kec: [String!]
    $kel: [String!]
    $produk: [String!]
    $types: SalesTargetType
    $orderBy: SalesTargetOrderType!
    $sortBy: SortByType!
  ) {
    listTarget(
      listTargetInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          from: $from
          to: $to
          role: $role
          tahun: $tahun
          bulan: $bulan
          country_id: $country_id
          region_id: $region_id
          provinsi: $prov
          kabupatens: $kab
          kecamatans: $kec
          kelurahans: $kel
          types: $types
          produk_id: $produk
        }
        order: { orderBy: $orderBy, sortBy: $sortBy }
      }
    ) {
      page
      limit
      total
      totalPage
      targets {
        id
        froms {
          id
          name
          role
        }
        tos {
          id
          name
          role
        }
        year
        month
        countrys {
          id
          country
        }
        regions {
          id
          region
        }
        provs {
          id
          province
        }
        kabs {
          id
          kabupaten
        }
        produknames {
          id
          brand
          price
        }
        target_quantity
        target_value
        tipe_target
      }
    }
  }
`;
export const Q_LIST_TARGET1 = gql`
  query listTarget(
    $page: Int
    $limit: Int
    $keyword: String
    $types: SalesTargetType
    $from: String
    $to: String
    $tahun: String
    $bulan: String
    $country_id: [ID!]
    $region_id: [ID!]
    $provinsi: [String!]
    $kabupatens: [String!]
    $kecamatans: [String!]
    $kelurahans: [String!]
    $produk_id: [String!]
  ) {
    listTarget(
      listTargetInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          types: $types
          from: $from
          to: $to
          tahun: $tahun
          bulan: $bulan
          country_id: $country_id
          region_id: $region_id
          provinsi: $provinsi
          kabupatens: $kabupatens
          kecamatans: $kecamatans
          kelurahans: $kelurahans
          produk_id: $produk_id
        }
        order: { orderBy: CREATED_AT, sortBy: ASC }
      }
    ) {
      page
      totalPage
      targets {
        id
        froms {
          id
          name
        }
        tos {
          id
          name
        }
        year
        month
        countrys {
          id
          country
        }
        regions {
          id
          region
        }
        provs {
          id
          province
        }
        kabs {
          id
          kabupaten
        }
        kecamatans {
          id
          kecamatan
        }
        kelurahans {
          id
          kelurahan
        }
        retailers1 {
          id
          name
        }
        retailers2 {
          id
          name
        }
        produknames {
          id
          brand
          price
        }
        price
        tipe_target
        target_quantity
        target_value
        isReported
        tipe_target
      }
    }
  }
`;
export const M_ADD_TARGET = gql`
  mutation addTarget(
    $from: String!
    $to: String!
    $produk: [ProdukTargetListInput!]!
  ) {
    createTarget(
      createTargetInput: { from: $from, to: $to, produkTarget: $produk }
    ) {
      id
    }
  }
`;
export const M_EDIT_TARGET = gql`
  mutation updateTarget(
    $id: ID!
    $from: String
    $to: String
    $month: String
    $year: String
    $country: Int
    $region: Int
    $produk: String
    $price: Int
    $qty: Int
    $value: String
    $tipe: SalesTargetType
  ) {
    updateTarget(
      updateTargetInput: {
        id: $id
        from: $from
        to: $to
        target_quantity: $qty
        target_value: $value
        month: $month
        year: $year
        country_id: $country
        region_id: $region
        produk_id: $produk
        price: $price
        tipe_target: $tipe
      }
    ) {
      id
      produk_id
    }
  }
`;

/* DAILY REPORT */
export const Q_LIST_DAILYREPORT = gql`
  query listDailyReport(
    $page: Int
    $limit: Int
    $keyword: String
    $user_id: [ID!]
    $region_id: [ID!]
    $start_date: DateTime
    $end_date: DateTime
  ) {
    listDailyReport(
      listDailyReportInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          user_id: $user_id
          region_id: $region_id
          start_date: $start_date
          end_date: $end_date
        }
        order: { sortBy: DESC, orderBy: TANGGAL }
      }
    ) {
      page
      limit
      total
      totalPage
      dailys {
        id
        user_id
        role
        region_id
        tanggal
        kegiatan
        d1
        hd1
        distributor1 {
          id
          name
        }
        d2
        hd2
        distributor2 {
          id
          name
        }
        r1
        hr1
        retailer1 {
          id
          name
        }
        retailer2 {
          id
          name
        }
        retailer3 {
          id
          name
        }
        petani1 {
          id
          name
        }
        petani2 {
          id
          name
        }
        r2
        hr2
        r3
        hr3
        p1
        hp1
        p2
        hp2
        nama_kebun
        hasil_kebun
        nama_marketing
        hasil_marketing
        nama_administrasi
        hasil_administrasi
        dokumentasi
      }
    }
  }
`;
export const M_ADD_DAILYREPORT = gql`
  mutation addDailyReport(
    $id: String!
    $role: roleType!
    $region: ID!
    $tanggal: DateTime!
    $kegiatan: dailyActivityType!
    $d1: ID
    $hd1: String
    $d2: ID
    $hd2: String
    $r1: ID
    $hr1: String
    $r2: ID
    $hr2: String
    $r3: ID
    $hr3: String
    $p1: String
    $hp1: String
    $p2: String
    $hp2: String
    $nk: String
    $hk: String
    $nm: String
    $hm: String
    $na: String
    $ha: String
    $dokumentasi: String
  ) {
    createDailyReport(
      createDailyReportInput: {
        user_id: $id
        role: $role
        region_id: $region
        tanggal: $tanggal
        kegiatan: $kegiatan
        d1: $d1
        hd1: $hd1
        d2: $d2
        hd2: $hd2
        r1: $r1
        hr1: $hr1
        r2: $r2
        hr2: $hr2
        r3: $r3
        hr3: $hr3
        p1: $p1
        hp1: $hp1
        p2: $p2
        hp2: $hp2
        nama_kebun: $nk
        hasil_kebun: $hk
        nama_marketing: $nm
        hasil_marketing: $hm
        nama_administrasi: $na
        hasil_administrasi: $ha
        dokumentasi: $dokumentasi
      }
    ) {
      id
    }
  }
`;
export const M_EDIT_DAILYREPORT = gql`
  mutation updateDailyReport(
    $id: ID!
    $role: roleType!
    $uid: String!
    $region: ID!
    $tanggal: DateTime!
    $kegiatan: dailyActivityType!
    $d1: ID
    $hd1: String
    $d2: ID
    $hd2: String
    $r1: ID
    $hr1: String
    $r2: ID
    $hr2: String
    $r3: ID
    $hr3: String
    $p1: String
    $hp1: String
    $p2: String
    $hp2: String
    $nk: String
    $hk: String
    $nm: String
    $hm: String
    $na: String
    $ha: String
    $dokumentasi: String
  ) {
    updateDailyReport(
      updateDailyReportInput: {
        id: $id
        user_id: $uid
        role: $role
        region_id: $region
        tanggal: $tanggal
        kegiatan: $kegiatan
        d1: $d1
        hd1: $hd1
        d2: $d2
        hd2: $hd2
        r1: $r1
        hr1: $hr1
        r2: $r2
        hr2: $hr2
        r3: $r3
        hr3: $hr3
        p1: $p1
        hp1: $hp1
        p2: $p2
        hp2: $hp2
        nama_kebun: $nk
        hasil_kebun: $hk
        nama_marketing: $nm
        hasil_marketing: $hm
        nama_administrasi: $na
        hasil_administrasi: $ha
        dokumentasi: $dokumentasi
      }
    ) {
      id
    }
  }
`;
export const M_DEL_DAILYREPORT = gql`
  mutation deleteDaily($id: ID!) {
    deleteDailyReport(id: $id) {
      id
    }
  }
`;

/* MONTHLY PLAN */
export const Q_LIST_MPLAN = gql`
  query listMonthlyplan(
    $page: Int
    $limit: Int
    $keyword: String
    $region_id: [ID!]
    $user_id: [ID!]
    $role: roleType
    $plan_type: monthlyPlanType
    $start_date: DateTime
    $end_date: DateTime
    $dateType: filterDateType
  ) {
    listMonthlyPlan(
      listMonthlyPlanInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          region_id: $region_id
          user_id: $user_id
          role_types: $role
          monthly_types: $plan_type
          start_date: $start_date
          end_date: $end_date
          dateType: $dateType
        }
        order: { orderBy: TANGGAL, sortBy: DESC }
      }
    ) {
      page
      limit
      total
      totalPage
      monthlys {
        id
        user_id
        users {
          name
        }
        role
        regions {
          id
          region
        }
        sales_promotor {
          id
          name
        }
        distributors {
          id
          name
          owner
        }
        retailers {
          id
          name
          owner
        }
        territory
        kegiatan
        tanggal
        lokasi
        tanamans {
          id
          crop
        }
        produks {
          id
          brand
        }
      }
    }
  }
`;
export const M_ADD_MPLAN = gql`
  mutation addMonthlyPlan(
    $uid: String!
    $role: roleType!
    $region_id: ID
    $tanggal: DateTime!
    $kegiatan: monthlyPlanType!
    $territory: String
    $sp: String
    $distributor: ID
    $retailer: ID
    $lokasi: String
    $tanaman: String
    $produk: String
  ) {
    createMonthlyPlan(
      createMonthlyPlanInput: {
        user_id: $uid
        role: $role
        region_id: $region_id
        tanggal: $tanggal
        kegiatan: $kegiatan
        territory: $territory
        sp_id: $sp
        distributor: $distributor
        retailer: $retailer
        lokasi: $lokasi
        tanaman_id: $tanaman
        produk_id: $produk
      }
    ) {
      id
    }
  }
`;
export const M_EDIT_MPLAN = gql`
  mutation updateMonthlyPlan(
    $id: ID!
    $uid: String!
    $role: roleType!
    $region_id: ID
    $tanggal: DateTime!
    $kegiatan: monthlyPlanType!
    $territory: String
    $sp: String
    $distributor: ID
    $retailer: ID
    $lokasi: String
    $tanaman: String
    $produk: String
  ) {
    updateMonthly(
      updateMonthlyInput: {
        id: $id
        user_id: $uid
        role: $role
        region_id: $region_id
        tanggal: $tanggal
        kegiatan: $kegiatan
        territory: $territory
        sp_id: $sp
        distributor: $distributor
        retailer: $retailer
        lokasi: $lokasi
        tanaman_id: $tanaman
        produk_id: $produk
      }
    ) {
      id
    }
  }
`;
export const M_DEL_MPLAN = gql`
  mutation delMonthlyPlan($id: ID!) {
    deleteMonthlyPlan(id: $id) {
      id
    }
  }
`;

/* KEGIATAN */
// export const Q_LIST_KEGIATAN = gql`
//   query listKegiatan(
//     $page: Int
//     $limit: Int
//     $keyword: String
//     $sp: [String!]
//     $tss: [String!]
//     $region: [Int!]
//     $kegiatan: kegiatanSalesPromotorType
//   ) {
//     listLaporanKegiatan(
//       listLaporanKegiatanInput: {
//         page: $page
//         limit: $limit
//         search: {
//           keyword: $keyword
//           sp_id: $sp
//           tss_id: $tss
//           region_id: $region
//           kegiatan: $kegiatan
//           start_date: ""
//           end_date: ""
//         }
//         order: { orderBy: CREATED_AT, sortBy: DESC }
//       }
//     ) {
//       page
//       total
//       totalPage
//       lapkegs {
//         sales_promotor {
//           id
//           name
//         }
//         id
//         country_id
//         region_id
//         province_id
//         kabupaten_id
//         sp_id
//         tss_id
//         tanggal_kegiatan
//         lokasi
//         kegiatan
//         petani_id
//         tanaman_id
//         hst
//         opt_sasaran
//         luas_demoplot
//         tanggal_aplikasi
//         tanggal_pengamatan
//         produk_id1
//         konsentrasi_rekomendasi1
//         produk_id2
//         konsentrasi_rekomendasi2
//         produk_id3
//         konsentrasi_rekomendasi3
//         volume
//         kesimpulan
//         dokumentasi
//         jml_petani_undang
//         jml_petani_hadir
//         jml_petani_aplikator
//         isExist
//         kekurangan
//         kelebihan
//         level_toko
//         retailer_id
//         jml_hasil_penjualan
//         jml_beli_langsung
//         produk_liquidasi
//         budget
//         hadiah
//         produk_spray1
//         keteranganProdukSpray1
//         produk_spray2
//         keteranganProdukSpray2
//         produk_spray4
//         keteranganProdukSpray4
//         produk_spray5
//         keteranganProdukSpray5
//         produk_spray6
//         keteranganProdukSpray6
//         produk_spray7
//         keteranganProdukSpray7
//         produk_spray8
//         keteranganProdukSpray8
//         produk_spray9
//         keteranganProdukSpray9
//         produk_spray10
//         keteranganProdukSpray10
//         fokus_produk
//         tujuan_sales_call
//         hasil_sales_call
//         stok
//         support_kegiatan
//         po_produk
//         pembayaran
//         countrys {
//           country
//         }
//         regions {
//           region
//         }
//         provinces {
//           province
//         }
//         kabupatens {
//           kabupaten
//         }
//         sales_promotor {
//           name
//         }
//         teritory_sales_supervisor {
//           name
//         }
//         petanis {
//           name
//           alamat
//           hp
//         }
//         tanamans {
//           crop
//         }
//         produknames1 {
//           brand
//         }
//         produknames2 {
//           brand
//         }
//         produknames3 {
//           brand
//         }
//         retailers {
//           name
//           owner
//           alamat
//           mobile
//         }
//         produkspray1 {
//           brand
//         }
//         produkspray2 {
//           brand
//         }
//         produkspray3 {
//           brand
//         }
//         produkspray4 {
//           brand
//         }
//         produkspray5 {
//           brand
//         }
//         produkspray6 {
//           brand
//         }
//         produkspray7 {
//           brand
//         }
//         produkspray8 {
//           brand
//         }
//         produkspray9 {
//           brand
//         }
//         produkspray10 {
//           brand
//         }
//         fokus_produks {
//           brand
//         }
//       }
//     }
//   }
// `;

export const Q_LIST_KEGIATAN = gql`
  query listLaporanKegiatan(
    $page: Int
    $limit: Int
    $keyword: String
    $sp_id: [String!]
    $tss_id: [String!]
    $region_id: [Int!]
    $start_date: DateTime
    $end_date: DateTime
    $kegiatan: kegiatanSalesPromotorType
    $dateType: filterDateType
  ) {
    listLaporanKegiatan(
      listLaporanKegiatanInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          sp_id: $sp_id
          tss_id: $tss_id
          region_id: $region_id
          start_date: $start_date
          end_date: $end_date
          kegiatan: $kegiatan
          dateType: $dateType
        }
        order: { orderBy: CREATED_AT, sortBy: DESC }
      }
    ) {
      page
      total
      totalPage
      lapkegs {
        id
        country_id
        region_id
        province_id
        kabupaten_id
        sp_id
        tss_id
        tanggal_kegiatan
        lokasi
        kegiatan
        petani_id
        tanaman_id
        hst
        opt_sasaran
        luas_demoplot
        tanggal_aplikasi
        tanggal_pengamatan
        volume
        kesimpulan
        dokumentasi
        jml_petani_undang
        jml_petani_hadir
        jml_petani_aplikator
        isExist
        kekurangan
        kelebihan
        level_toko
        retailer_id
        jml_hasil_penjualan
        jml_beli_langsung
        produk_liquidasi
        budget
        hadiah
        fokus_produk
        countrys {
          id
          country
        }
        regions {
          id
          region
        }
        provinces {
          id
          province
        }
        kabupatens {
          id
          kabupaten
        }
        sales_promotor {
          id
          name
        }
        teritory_sales_supervisor {
          id
          name
        }
        petanis {
          id
          name
          alamat
          hp
          luas
          ucrops {
            id
            crop
          }
          ocrops {
            id
            crop
          }
        }
        tanamans {
          id
          crop
        }
        retailers {
          id
          retailer_type
          name
          alamat
          owner
          mobile
        }
        fokus_produks {
          id
          brand
        }
        aplikasiKegiatan {
          id
          produk {
            id
            brand
          }
          konsentrasi_rekomendasi
        }
        LiquidasiKegiatan {
          id
          jumlah_produk
          produk {
            id
            brand
          }
        }
        salesKegiatan {
          laporan_kegiatan_id
          tujuan_sales_call_petani
          tujuan_sales_call_kios
          stok
          support_kegiatan
          po_produk
          pembayaran
        }
      }
    }
  }
`;
