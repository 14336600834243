<template>
  <div>
    <div class="row table-utilities mx-0">
      <div class="row ml-2 w-100">
        <div class="col-md-10 search-section">
          <div class="input-group">
            <div class="input-group-prepend bg-white">
              <span class="input-group-text" id="basic-addon1">
                <div class="font-12">
                  <i class="ri-search-line ri-lg ri-sub"></i>
                </div>
              </span>
            </div>
            <input
              type="text"
              class="form-control search-input"
              :placeholder="$t('dailyreport.search')"
              @input="setPage"
              v-model="filterInput"
            />
          </div>
        </div>
        <div class="col-md-2 pl-0">
          <button
            class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
            @click="handleExport"
            :disabled="isLoading"
          >
            <div class="font-12">
              <div
                v-if="isLoading"
                class="spinner-border spinner-border-sm text-success mr-1"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
            </div>
            {{ $t("global.export") }}
          </button>
        </div>
      </div>
      <div class="row w-100 ml-2 mt-2">
        <div class="col-md-2 pl-0">
          <b-button
            class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
            id="popover-button-filter-1"
            href="#"
            tabindex="0"
          >
            <div class="font-12">
              <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
            </div>
            Filter Data
          </b-button>
        </div>
        <b-popover
          placement="right"
          target="popover-button-filter-1"
          triggers="focus"
        >
          <b-tabs content-class="mt-3">
            <b-tab title="Tanggal" active>
              <div class="form-check multiple-row">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-today"
                  :value="filterDateTypes[0]"
                  @change="resetFilter1"
                  checked
                  v-model="filterDateType"
                />
                <label class="form-check-label" for="radio-today">
                  <div class="col">
                    <div class="row ssp-14">Today</div>
                    <div class="row">{{ today | formatDate2 }}</div>
                  </div>
                </label>
              </div>
              <div class="form-check multiple-row">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-yesterday"
                  :value="filterDateTypes[1]"
                  @change="resetFilter1"
                  v-model="filterDateType"
                />
                <label class="form-check-label" for="radio-yesterday">
                  <div class="col">
                    <div class="row ssp-14">Yesterday</div>
                    <div class="row">{{ yesterday | formatDate2 }}</div>
                  </div>
                </label>
              </div>
              <div class="form-check multiple-row">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-week"
                  :value="filterDateTypes[2]"
                  v-model="filterDateType"
                />
                <label class="form-check-label" for="radio-week">
                  <div class="col">
                    <div class="row ssp-14">This week</div>
                    <div class="row">
                      <span>{{ `${getDay(firstDayWeek)}&nbsp;-&nbsp;` }}</span>
                      <span>{{ lastDayWeek | formatDate2 }}</span>
                    </div>
                  </div>
                </label>
              </div>
              <div class="form-check multiple-row">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-month"
                  :value="filterDateTypes[3]"
                  v-model="filterDateType"
                />
                <label class="form-check-label" for="radio-month">
                  <div class="col">
                    <div class="row ssp-14">This month</div>
                    <div class="row">
                      <span>{{ `${getDay(firstDayMonth)}&nbsp;-&nbsp;` }}</span>
                      <span>{{ lastDayMonth | formatDate2 }}</span>
                    </div>
                  </div>
                </label>
              </div>
              <div class="form-check mb-2">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="radio-custom"
                  :value="filterDateTypes[4]"
                  v-model="filterDateType"
                />
                <label class="form-check-label ssp-14" for="radio-custom">
                  Custom Range
                </label>
              </div>
              <b-row v-if="filterDateType == filterDateTypes[4]" class="mb-2">
                <b-col cols="6" class="pr-1">
                  <span>FROM</span>
                  <input
                    type="date"
                    class="form-control float-right"
                    v-model="filterSelected1"
                  />
                </b-col>
                <b-col cols="6" class="pl-1">
                  <span>TO</span>
                  <input
                    type="date"
                    class="form-control"
                    v-model="filterSelected2"
                  />
                </b-col>
              </b-row>
              <button
                @click="resetFilter"
                type="string"
                class="btn btn-success w-100 ssp-14"
                :class="{ disabled: filterDateType === null }"
              >
                {{ $t("filter.reset") }}
              </button>
            </b-tab>
            <b-tab title="User">
              <div class="filter-overflow-srch">
                <div
                  class="form-checkbox"
                  v-for="user in dataUser"
                  :key="user.id"
                >
                  <input
                    class="form-checkbox-item"
                    type="checkbox"
                    name="kegiatan-label"
                    :id="user.id"
                    :value="user.id"
                    v-model="filterRoleApply"
                  />
                  <label class="form-checkbox-label ssp-14-400" :for="user.id">
                    {{ user.name }}
                  </label>
                </div>
              </div>
              <button
                @click="resetFilter"
                type="string"
                class="btn btn-success w-100 mt-2 ssp-14"
                :class="{ disabled: filterRoleApply == '' }"
              >
                {{ $t("filter.reset") }}
              </button>
            </b-tab>
            <!-- <b-tab title="Region">
              <div class="filter-overflow-srch">
                <div
                  class="form-checkbox"
                  v-for="rg in dataRegion"
                  :key="rg.id"
                >
                  <input
                    class="form-checkbox-item"
                    type="checkbox"
                    name="kegiatan-label"
                    :value="parseInt(rg.id)"
                    :id="rg.id"
                    v-model="filterRegion"
                  />
                  <label class="form-checkbox-label ssp-14-400" :for="rg.id">
                    {{ rg.region }}
                  </label>
                </div>
              </div>
              <button
                @click="resetFilter"
                type="string"
                class="btn btn-success w-100 mt-2 ssp-14"
                :class="{ disabled: filterRegion.length === 0 }"
              >
                {{ $t("filter.reset") }}
              </button>
            </b-tab> -->
            <b-tab title="Kegiatan">
              <div class="filter-overflow">
                <div
                  class="form-checkbox"
                  v-for="kegiatan in typeKegiatanOptions"
                  :key="kegiatan.id"
                >
                  <input
                    :id="kegiatan.value"
                    class="form-radio-item"
                    type="radio"
                    name="kegiatan-label"
                    :value="kegiatan.value"
                    v-model="filterKegiatan"
                  />
                  <label
                    class="form-radio-label ssp-14-400"
                    :for="kegiatan.value"
                  >
                    {{ kegiatan.label }}
                  </label>
                </div>
              </div>
              <button
                @click="resetFilter"
                type="string"
                class="btn btn-success w-100 mt-2 ssp-14"
                :class="{ disabled: filterKegiatan === null }"
              >
                {{ $t("filter.reset") }}
              </button>
            </b-tab>
          </b-tabs>
        </b-popover>
      </div>
    </div>
    <div class="table-borderless">
      <b-table-simple hover responsive>
        <b-thead class="bv-head">
          <b-tr>
            <b-th
              ><input type="checkbox" @click="selectAll" v-model="allSelected"
            /></b-th>
            <b-th v-for="hd in $t('dailyreport.headers')" :key="hd.id">
              {{ hd }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="this.dataLaporan.length !== 0">
          <b-tr
            class="ssp-16-400"
            v-for="laporan in dataLaporan"
            :key="laporan.id"
          >
            <b-td
              ><input
                type="checkbox"
                :value="laporan.id"
                v-model="lapIDs"
                @click="select"
            /></b-td>
            <b-td>
              <span>
                {{ laporan.users.name }}
              </span>
              <!-- <span class="badge badge-gray">
                {{ laporan.role }}
              </span> -->
            </b-td>
            <b-td>{{ laporan.tanggal | formatDate2 }}</b-td>
            <b-td>{{ laporan.createdAt | formatDate2 }}</b-td>
            <b-td>
              <span class="badge badge-blue">{{
                convertKegiatan(laporan.kegiatan)
              }}</span>
            </b-td>
            <b-td class="action-column">
              <div class="action-wrapper font-12">
                <i
                  class="ri-eye-fill ri-lg ri-mid action-btn"
                  @click="onDetail(laporan)"
                ></i>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tbody v-else class="text-center text-italic">
          <b-tr>
            <b-td colspan="10">{{ $t("global.empty") }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div class="row align-items-center table-footer">
        <div class="col-sm-6 ssp-14-700">
          {{ $t("global.total_data") }} {{ this.totalData }}
        </div>
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-7 align-self-center page-text">
              {{ $t("global.page_on") }}
            </div>
            <div class="col float-right">
              <div class="row">
                <select class="form-control pagination-pg" v-model="currPage">
                  <option
                    v-for="index in totalPages"
                    :key="index.id"
                    :value="index"
                  >
                    {{ index++ }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col page-arrow ssp-14-700 ml-2">
              <div class="row float-right">
                <div
                  @click="redcPage"
                  class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                >
                  <div class="font-12" :class="{ orange: currPage > 1 }">
                    <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                  </div>
                </div>
                <div
                  @click="addPage"
                  class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                >
                  <div
                    class="font-12"
                    :class="{ orange: currPage < totalPages }"
                  >
                    <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-daily-report" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">
          <img
            class="mr-2"
            src="@/assets/img/tbhdata-form.png"
            alt="FORM"
            style="height: 1.8rem;"
          />
          {{ modalTitle }}
        </h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <!-- <p class="">Modal Body with button</p> -->
        <form>
          <div class="form-group">
            <label for="tanggal-laporan" class="ssp-16 text-black">
              {{ $t("liquidation.label.from") }}
            </label>
            <input
              type="text"
              class="form-control"
              v-model="details.name"
              :disabled="true"
            />
          </div>

          <div class="form-group">
            <label for="tanggal-laporan" class="ssp-16 text-black">
              {{ $t("sp_act.label.act_type") }}
            </label>
            <input
              type="text"
              class="form-control"
              v-model="details.kegiatan"
              :disabled="true"
            />
          </div>

          <div class="form-group">
            <label for="tanggal-laporan" class="ssp-16 text-black">
              {{ $t("dailyreport.label.date") }}
            </label>
            <datepicker
              input-class="form-control"
              v-model="details.tanggal"
              :disabled="true"
            />
          </div>

          <section v-if="fieldKeg === 'VD'">
            <div class="form-group">
              <label for="distributor" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vd") }} 1</label
              >
              <input
                type="text"
                class="form-control"
                v-model="details.distributor1"
                :disabled="true"
              />
            </div>
            <div class="form-group mb-2">
              <label for="hd2" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                v-model="details.h_distributor1"
                :disabled="true"
              ></textarea>
            </div>
            <div v-if="expandDist" class="form-group mt-4">
              <label for="distributor" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vd") }} 2</label
              >
              <input
                type="text"
                class="form-control"
                v-model="details.distributor2"
                :disabled="true"
              />
            </div>
            <div v-if="expandDist" class="form-group">
              <label for="hd2" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                v-model="details.h_distributor2"
                :disabled="true"
              ></textarea>
            </div>
          </section>

          <section v-if="fieldKeg === 'VR1'">
            <div class="form-group">
              <label for="retailer1" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R1 1</label
              >
              <input
                type="text"
                class="form-control"
                v-model="details.retailer1"
                :disabled="true"
              />
            </div>
            <div class="form-group mb-2">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                v-model="details.h_retailer1"
                :disabled="true"
              ></textarea>
            </div>
            <div v-if="expandRet1" class="form-group mt-4">
              <label for="retailer2" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R1 2</label
              >
              <input
                type="text"
                class="form-control"
                v-model="details.retailer2"
                :disabled="true"
              />
            </div>
            <div v-if="expandRet1" class="form-group mb-2">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                v-model="details.h_retailer2"
                :disabled="true"
              ></textarea>
            </div>
            <div v-if="expandRet2" class="form-group mt-4">
              <label for="retailer3" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R1 3</label
              >
              <input
                type="text"
                class="form-control"
                v-model="details.retailer3"
                :disabled="true"
              />
            </div>
            <div v-if="expandRet2" class="form-group mb-2">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                v-model="details.h_retailer3"
                :disabled="true"
              ></textarea>
            </div>
          </section>

          <section v-if="fieldKeg === 'VR2'">
            <div class="form-group">
              <label for="retailer1" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R2 1</label
              >
              <input
                type="text"
                class="form-control"
                v-model="details.retailer1"
                :disabled="true"
              />
            </div>
            <div class="form-group mb-2">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                v-model="details.h_retailer1"
                :disabled="true"
              ></textarea>
            </div>
            <div v-if="expandRet1" class="form-group mt-4">
              <label for="retailer2" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R2 2</label
              >
              <input
                type="text"
                class="form-control"
                v-model="details.retailer2"
                :disabled="true"
              />
            </div>
            <div v-if="expandRet1" class="form-group">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                v-model="details.h_retailer2"
                :disabled="true"
              ></textarea>
            </div>
            <div v-if="expandRet2" class="form-group mt-4">
              <label for="retailer3" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R2 3</label
              >
              <input
                type="text"
                class="form-control"
                v-model="details.retailer3"
                :disabled="true"
              />
            </div>
            <div v-if="expandRet2" class="form-group mb-2">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                v-model="details.h_retailer3"
                :disabled="true"
              ></textarea>
            </div>
          </section>

          <section v-if="fieldKeg === 'VF'">
            <div class="form-group">
              <label for="farmer1" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.farmer") }} 1</label
              >
              <input
                type="text"
                class="form-control"
                v-model="details.farmer1"
                :disabled="true"
              />
            </div>
            <div class="form-group mb-2">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                v-model="details.h_farmer1"
                :disabled="true"
              ></textarea>
            </div>
            <div v-if="expandFarm" class="form-group mt-4">
              <label for="farmer2" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.farmer") }} 2</label
              >
              <input
                type="text"
                class="form-control"
                v-model="details.farmer2"
                :disabled="true"
              />
            </div>
            <div v-if="expandFarm" class="form-group mb-2">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                v-model="details.h_farmer2"
                :disabled="true"
              ></textarea>
            </div>
          </section>

          <section v-if="fieldKeg === 'VK'">
            <div class="form-group">
              <label for="nama-kebun" class="ssp-16 text-black">{{
                $t("dailyreport.label.vk")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="nama-kebun"
                v-model="details.kebun"
                :disabled="true"
              />
            </div>
            <label class="ssp-16 text-black">{{
              $t("dailyreport.label.result")
            }}</label>
            <textarea
              class="form-control no-resize"
              id="lokasi-kegiatan"
              cols="30"
              rows="5"
              v-model="details.h_kebun"
              :disabled="true"
            ></textarea>
          </section>

          <section v-if="fieldKeg === 'MA'">
            <div v-if="fieldKeg === 'MA'" class="form-group">
              <label for="nama-kegmr" class="ssp-16 text-black">{{
                $t("dailyreport.label.ma")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="nama-kegmr"
                v-model="details.marketing"
                :disabled="true"
              />
            </div>
            <label class="ssp-16 text-black">{{
              $t("dailyreport.label.result")
            }}</label>
            <textarea
              class="form-control no-resize"
              id="lokasi-kegiatan"
              cols="30"
              rows="5"
              v-model="details.h_marketing"
              :disabled="true"
            ></textarea>
          </section>

          <section v-if="fieldKeg === 'ADM'">
            <div v-if="fieldKeg === 'ADM'" class="form-group">
              <label for="nama-adm" class="ssp-16 text-black">{{
                $t("dailyreport.label.adm")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="nama-adm"
                v-model="details.administ"
                :disabled="true"
              />
            </div>
            <div v-if="fieldKeg === 'ADM'" class="form-group">
              <label class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                v-model="details.h_administ"
                :disabled="true"
              ></textarea>
            </div>
          </section>

          <div class="form-group mt-3">
            <label class="ssp-16 text-black">
              {{ $t("dailyreport.label.doc") }}
            </label>
            <div class="row">
              <div class="col-md-10 offset-1 mt-3">
                <img
                  class="d-block ml-auto mr-auto"
                  v-if="details.dokumentasi != null"
                  :src="url + details.dokumentasi"
                  :alt="details.dokumentasi"
                />
                <div v-else class="mx-auto text-center">
                  {{ $t("feedback.dnot_avb") }}
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { Q_LIST_DAILYREPORT } from "@/graphql/queries";
import { Q_LIST_REGION, Q_LIST_USERSEARCH } from "@/graphql/queries";
import { TypeKegiatan, RoleType, filterDateType } from "@/graphql/enum.type.js";
import JwtService from "@/core/services/jwt.service";
import Axios from "axios";

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      isLoading: false,
      dataLaporan: [],
      dataRegion: [],

      url: JwtService.getURL(),

      dataUser: [],
      dataUserID: [],
      filterRole: null,
      filterRegion: [],
      filterRoleApply: [],
      searchUser: "",

      roleOptions: [RoleType.RM, RoleType.AM, RoleType.TSS, RoleType.SP],
      filterDateTypes: [
        filterDateType.TODAY,
        filterDateType.YESTERDAY,
        filterDateType.THIS_WEEK,
        filterDateType.THIS_MONTH,
        filterDateType.CUSTOM_DATE
      ],

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",
      filterKegiatan: null,

      customFilter: false,
      filterSelected1: "",
      filterSelected2: "",
      filterDateType: null,

      today: moment(new Date(new Date().setHours(0, 0, 0, 0))).format(
        "YYYY-MM-DD"
      ),
      yesterday: moment(
        new Date(new Date().setHours(0, 0, 0, 0) - 86400000)
      ).format("YYYY-MM-DD"),
      firstDayWeek: moment()
        .startOf("week")
        .toDate(),
      lastDayWeek: moment()
        .endOf("week")
        .toDate(),
      firstDayMonth: null,
      lastDayMonth: null,

      lapIDs: [],
      selected: [],
      allSelected: false,

      typeKegiatanOptions: [
        TypeKegiatan.VD,
        TypeKegiatan.VR1,
        TypeKegiatan.VR2,
        TypeKegiatan.VF,
        TypeKegiatan.VK,
        TypeKegiatan.MA,
        TypeKegiatan.ADM
      ],

      modalTitle: "",
      isDisabled: false,

      // invoiceFile: [], // Store our uploaded files
      retailerSelected: "",

      fieldKeg: null,

      expandDist: false,
      expandRet1: false,
      expandRet2: false,
      expandFarm: false,

      details: {}
    };
  },
  apollo: {
    listDailyReport: {
      query: () => Q_LIST_DAILYREPORT,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          user_id: this.filterRoleApply,
          region_id: this.getRegionID(),
          type: this.filterKegiatan,
          role_types: RoleType.AM,
          start_date: this.filterSelected1,
          end_date: this.filterSelected2,
          dateType: this.filterDateType
        };
      },
      debounce: 500,
      result({ data }) {
        this.dataLaporan = data.listDailyReport.dailys;
        this.totalPages = data.listDailyReport.totalPage;
        this.totalData = data.listDailyReport.total;
      },
      error(err) {
        this.catchError(err);
      }
    },
    listUser: {
      query: () => Q_LIST_USERSEARCH,
      variables() {
        return {
          keyword: "",
          role: RoleType.AM,
          country_id: this.getCountryID(),
          region_id: this.getRegionID(),
          prov_id: [],
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: [],
          orderBy: "NAME",
          sortBy: "ASC"
        };
      },
      // update: data => data.listUser,
      result({ data }) {
        this.dataUser = data.listUser.users;
        this.dataUserID = data.listUser.users.map(a => {
          return a.id;
        });
      }
    },
    listRegion: {
      query: () => Q_LIST_REGION,
      result({ data }) {
        const r = this.getRegionID();
        if (r.length > 0) {
          this.dataRegion = data.listRegion.regions.filter(reg => {
            if (r.includes(parseInt(reg.id))) {
              return reg;
            }
          });
        } else {
          this.dataRegion = data.listRegion.regions;
        }
      }
    }
  },
  computed: {},
  methods: {
    resetKeg() {
      this.expandDist = false;
      this.expandRet1 = false;
      this.expandRet2 = false;
      this.expandFarm = false;
    },
    getDay(value) {
      return moment(value).format("DD");
    },
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    setFilterSelectedWeek() {
      this.filterSelected2 = this.lastDayWeek;
      this.setPage();
      this.customFilter = false;
    },
    setFilterSelectedMonth() {
      this.filterSelected2 = this.lastDayMonth;
      this.setPage();
      this.customFilter = false;
    },
    resetFilter() {
      this.filterSelected1 = "";
      this.filterSelected2 = "";
      this.filterDateType = null;
      this.filterKegiatan = null;
      this.filterRoleApply = [];
      this.filterRegion = [];
      this.searchUser = "";
    },
    resetFilter1() {
      this.filterSelected2 = "";
      this.setPage();
      this.customFilter = false;
    },

    selectAll() {
      this.lapIDs = [];
      if (!this.allSelected) {
        for (let i in this.dataLaporan) {
          this.lapIDs.push(this.dataLaporan[i].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    },
    onDetail(data) {
      this.modalTitle = this.$t("dailyreporting.detail");
      this.fieldKeg = data.kegiatan;
      this.isDisabled = true;
      this.resetKeg();

      this.details.name = data.users ? data.users.name : "-";
      this.details.kegiatan = this.convertKegiatan(data.kegiatan);

      this.details.tanggal = this.formatDate(data.tanggal);
      if (data.d1 !== null) {
        this.details.distributor1 = `${data.distributor1.name} (${data.distributor1.owner})`;
        this.details.h_distributor1 = data.hd1;
      }
      if (data.d2 !== null) {
        this.expandDist = true;
        this.details.distributor2 = `${data.distributor1.name} (${data.distributor1.owner})`;
        this.details.h_distributor2 = data.hd2;
      }
      if (data.r1 !== null) {
        this.details.retailer1 = `${data.retailer1.name} (${data.retailer1.owner})`;
        this.details.h_retailer1 = data.hr1;
      }
      if (data.r2 !== null) {
        this.expandRet1 = true;
        this.details.retailer2 = `${data.retailer2.name} (${data.retailer2.owner})`;
        this.details.h_retailer2 = data.hr2;
      }
      if (data.r3 !== null) {
        this.expandRet2 = true;
        this.details.retailer3 = `${data.retailer3.name} (${data.retailer3.owner})`;
        this.details.h_retailer3 = data.hr3;
      }
      if (data.p1 !== null) {
        this.details.farmer1 = data.petani1.name;
        this.details.h_farmer1 = data.hp1;
      }
      if (data.p2 !== null) {
        this.expandFarm = true;
        this.details.farmer2 = data.petani2.name;
        this.details.h_farmer2 = data.hp2;
      }
      this.details.kebun = data.nama_kebun;
      this.details.h_kebun = data.hasil_kebun;
      this.details.marketing = data.nama_marketing;
      this.details.h_marketing = data.hasil_marketing;
      this.details.administ = data.nama_administrasi;
      this.details.h_administ = data.hasil_administrasi;
      this.details.dokumentasi = data.dokumentasi;

      this.$bvModal.show("modal-daily-report");
    },
    closeModal() {
      this.$bvModal.hide("modal-daily-report");
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    convertKegiatan(data) {
      let Value;
      switch (data) {
        case "VD":
          Value = "Visit Distributor";
          break;
        case "VR1":
          Value = "Visit Retailer 1";
          break;
        case "VR2":
          Value = "Visit Retailer 2";
          break;
        case "VF":
          Value = "Visit Farmer";
          break;
        case "VK":
          Value = "Visit Kebun";
          break;
        case "MA":
          Value = "Marketing Activity";
          break;
        case "ADM":
          Value = "Administration";
          break;
        default:
          Value = "Wrong Activity";
          break;
      }
      return Value;
    },
    async handleExport() {
      this.isLoading = true;
      await Axios.post(
        process.env.VUE_APP_URL_GRAPHQL + "/daily-report/excel",
        {
          dateType: this.handleCustomDateExport(this.filterDateType),
          start_date: this.filterSelected1,
          end_date: this.filterSelected2,
          user:
            this.filterRoleApply.length === 0
              ? this.dataUserID
              : this.filterRoleApply,
          region: [],
          kegiatan: this.convertKegDaily(this.filterKegiatan)
        },
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + JwtService.getToken(),
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `dailyreport-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    }
  },
  mounted() {
    const dateNow = new Date(new Date().setHours(0, 0, 0, 0));
    this.firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
    this.lastDayMonth = new Date(
      dateNow.getFullYear(),
      dateNow.getMonth() + 1,
      0
    );
  },
  created() {
    this.filterRole = this.roleOptions[0];
  }
};
</script>

<style></style>
